import Cookies from "js-cookie";

class GalletonBotones {

    constructor() {
        this.errores = []
    }

    setup() {

        this.buscarBotones()
        if (this.errores.length) {
            return false
        }
    }

    buscarBotones() {
        this.botonAceptarTodas = document.getElementById('galleton-boton-aceptar')
        this.botonAceptarSeleccionadas = document.getElementById('galleton-boton-aceptar-seleccionadas')
        this.botonRechazar = document.getElementById('galleton-boton-rechazar')

        if (!this.botonAceptarTodas) {
            this.errores.push('Falta el botón "galleton-boton-aceptar"')
        }

        if (!this.botonAceptarSeleccionadas) {
            this.errores.push('Falta el botón "galleton-boton-aceptar-seleccionadas"')
        }

        if (!this.botonRechazar) {
            this.errores.push('Falta el botón "galleton-boton-rechazar"')
        }
    }

    getBotonAceptarTodas() {
        return this.botonAceptarTodas
    }

    getBotonAceptarSeleccionadas() {
        return this.botonAceptarSeleccionadas
    }

    getBotonRechazar() {
        return this.botonRechazar
    }

    hayErrores() {
        return this.errores.length
    }

    getErrores() {
        return this.errores
    }
}

export default GalletonBotones
