class GrupoCookie {

    constructor(nombreGrupo, titulo, activo, obligatorio) {
        this.nombreGrupo = nombreGrupo
        this.titulo = titulo
        this.activo = activo
        this.obligatorio = obligatorio
    }

    html() {
        let checked = ''
        if (this.activo) {
            checked = 'checked="checked"'
        }
        let desactivable = ''
        if (this.obligatorio) {
            desactivable = 'disabled'
        }
        return '<div class="custom-control custom-switch col">\n' +
            '<input type="checkbox" class="custom-control-input" id="galleton-' + this.nombreGrupo + '" ' + desactivable + ' ' + checked + '>\n' +
            '<label class="custom-control-label" for="galleton-' + this.nombreGrupo + '">' + this.titulo + '</label>\n' +
            '</div>\n'
    }
}

export default GrupoCookie
