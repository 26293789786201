import GrupoCookie from "./grupo-cookie";
import Cookies from 'js-cookie'
import GalletonLoger from "./log";
import ValidadorHtml from "./validador-html";
import Botones from "./botones";

class HtmlPopup {
    htmlAntes() {
        return '<section class="section--cookies active" id="galleton-popup">\n' +
            '<article class="container">\n' +
            '<div class="row align-items-center">\n' +
            '<div class="col-12 col-md-8 col-lg-9 small">\n' +
            '    <div class="cookies--text">\n' +
            '    <!-- <h2 class="h4">Tu privacidad es importante para nosotros.</h2> -->\n' +
            '    <p>Nosotros y nuestros socios utilizamos tecnologías, como las cookies, y procesamos datos personales, como las direcciones IP y los identificadores de cookies, para personalizar los anuncios y el contenido según sus intereses, medir el rendimiento de los anuncios y el contenido y obtener información sobre las audiencias que vieron los anuncios y el contenido.</p>\n' +
            '    <p>Haga clic a continuación para autorizar el uso de esta tecnología y el procesamiento de sus datos personales para estos fines. Puede cambiar de opinión y cambiar sus opciones de consentimiento en cualquier momento al regresar a este sitio.</p>\n' +
            '    </div>\n' +
            '    <div class="container">\n' +
            '    <div class="row row-cols-2 row-cols-lg-4 py-3">' +
            '[SWITCHES]' +
            '    </div>\n' +
            '</div>' +
            '<p><a class="d-block mb-3 mb-lg-0" href="/politica-cookies">Puede consultar nuestra política de cookies aquí.</a></p>\n' +
            '</div>\n' +
            '\n' +
            '<div class="col-12 col-md-4 col-lg-3">\n' +
            '    <button class="btn btn-primary btn-block" id="galleton-boton-aceptar">Aceptar todas</button>\n' +
            '    <button class="btn btn-outline-primary btn-block rechazar-cookies" id="galleton-boton-aceptar-seleccionadas">Aceptar seleccionadas</button>\n' +
            '    <button class="btn btn-outline-secondary btn-block rechazar-cookies" id="galleton-boton-rechazar">Rechazar cookies</button>\n' +
            '</div>\n' +
            '</div>\n' +
            '</article>\n' +
            '</section>'
    }
}

window.galletonCookies = {
    setup(opciones) {
        this.opciones = opciones

        if (!this.opciones.domElement) {
            this.opciones.domElement = 'galleton-cookies'
        }
        this.contenedor = document.getElementById(this.opciones.domElement);
        var popup = document.createElement('div')
        var contenidoPopup = '';
        var cookies = Cookies.getJSON('galleton')
        let htmlPopUp = new HtmlPopup()
        this.logger = new GalletonLoger(this.opciones.log)

        if (typeof cookies !== 'undefined') {
            return
        }

        if (!this.contenedor) {
            this.logger.log('Como no hay elemento <div id="' + this.opciones.domElement + '"></div> en tu página añadimos el popup al <body></body>')
            this.contenedor = document.body
        }

        if (!this.opciones.useCustomCss) {
            this.cargarCss()
        }

        if (this.opciones.popupContent) {
            let validadorHtml = new ValidadorHtml(this.opciones.popupContent)
            validadorHtml.validar()
            if (!validadorHtml.esValido()) {
                this.logger.log(validadorHtml.errores())
            }
        }

        contenidoPopup = this.opciones.popupContent || htmlPopUp.htmlAntes()

        let switches = ''

        let grupoEssential = new GrupoCookie('essential',
            this.opciones.cookies.essential.title[this.opciones.language],
            true,
            true)

        let grupoMarketing = new GrupoCookie('marketing',
            this.opciones.cookies.marketing.title[this.opciones.language],
            cookies ? cookies.marketing : false,
            false)

        let grupoPersonalization = new GrupoCookie('personalization',
            this.opciones.cookies.personalization.title[this.opciones.language],
            cookies ? cookies.personalization : false,
            false)

        let grupoAnalytics = new GrupoCookie('analytics',
            this.opciones.cookies.analytics.title[this.opciones.language],
            cookies ? cookies.analytics : false,
            false)

        if (this.opciones.cookies.essential.display) {
            switches += grupoEssential.html()
        }

        if (this.opciones.cookies.marketing.display) {
            switches += grupoMarketing.html()
        }
        if (this.opciones.cookies.personalization.display) {
            switches += grupoPersonalization.html()
        }
        if (this.opciones.cookies.analytics.display) {
            switches += grupoAnalytics.html()
        }

        contenidoPopup = contenidoPopup.replace('[SWITCHES]', switches)

        let nofollow = ''
        if (this.opciones.galletonLinkNoFollow) {
            nofollow = 'rel="nofollow"'
        }
        let poweredBy = '<a href="https://urlanheat.com/blog/galleton" ' + nofollow + '>Powered by Galleton</a>'
        contenidoPopup = contenidoPopup.replace('[POWERED_BY]', poweredBy)

        popup.innerHTML = contenidoPopup
        this.contenedor.appendChild(popup)

        let botones = new Botones()
        botones.setup()
        if (botones.hayErrores()) {
            this.log(botones.getErrores())
            return false
        }

        botones.getBotonAceptarTodas().addEventListener('click', function(e){
            e.preventDefault()
            this.callbackAceptarTodas()
        }.bind(this))

        botones.getBotonAceptarSeleccionadas().addEventListener('click', function(e){
            e.preventDefault()
            var cookiesMarketing = document.getElementById('galleton-marketing')
            var cookiesPersonalizacion = document.getElementById('galleton-personalization')
            var cookiesAnalytics = document.getElementById('galleton-analytics')

            Cookies.set('galleton', {
                essential: true,
                marketing: cookiesMarketing.checked,
                personalization: cookiesPersonalizacion.checked,
                analytics: cookiesAnalytics.checked,
            }, {
                expires: this.opciones.expiryDays
            })
            this.lanzarCallbacks()
            this.borrarCookiesNoSolicitadas()
            this.ocultarPopup()
        }.bind(this))

        botones.getBotonRechazar().addEventListener('click', function(e){
            e.preventDefault()

            Cookies.set('galleton', {
                essential: true,
                marketing: false,
                personalization: false,
                analytics: false,
            }, {
                expires: this.opciones.expiryDays
            })
            this.borrarCookiesNoSolicitadas()
            this.ocultarPopup()
        }.bind(this))

        // Miramos si hay que lanzar los callbacks
        this.lanzarCallbacks()
        this.borrarCookiesNoSolicitadas()

    },

    cargarCss() {
        var link = document.createElement( "link" );
        link.href = 'https://galleton.urlanheat.com/cookies.css';
        link.type = "text/css";
        link.rel = "stylesheet";
        link.media = "screen,print";

        document.getElementsByTagName( "head" )[0].appendChild( link );
    },

    lanzarCallbacks() {
        this.logger.log("Comprobar si hay que lanzar las callbacks")
        var cookies = Cookies.getJSON('galleton')

        if (typeof cookies==='undefined') {
            return
        }

        this.logger.log(cookies)
        if (cookies.essential) {
            this.logger.log("Callback cookies esenciales")
            if (typeof this.opciones.cookies.essential.callbacks!=='undefined') {
                this.opciones.cookies.essential.callbacks.forEach(callback => {
                    callback()
                })
            }
        }
        if (cookies.marketing) {
            this.logger.log("Callback cookies marketing")
            if (typeof this.opciones.cookies.marketing.callbacks!=='undefined') {
                this.opciones.cookies.marketing.callbacks.forEach(callback => {
                    callback()
                })
            }
        }
        if (cookies.personalization) {
            this.logger.log("Callback cookies personalización")
            if (typeof this.opciones.cookies.personalization.callbacks!=='undefined') {
                this.opciones.cookies.personalization.callbacks.forEach(callback => {
                    callback()
                })
            }
        }
        if (cookies.analytics) {
            this.logger.log("Callback cookies analytics")
            if (typeof this.opciones.cookies.analytics.callbacks!=='undefined') {
                this.opciones.cookies.analytics.callbacks.forEach(callback => {
                    callback()
                })
            }
        }
    },

    borrarCookiesNoSolicitadas() {
        var cookies = Cookies.getJSON('galleton')

        if (typeof cookies==='undefined') {
            return
        }

        if (!cookies.analytics) {
            this.opciones.cookies.analytics.list.forEach(cookie => {
                this.logger.log("Eliminar cookie: " + cookie)
                Cookies.remove(cookie)
            })
        }

    },

    callbackAceptarTodas() {
        console.log("Aceptar todas")
        console.log(this)
        Cookies.set('galleton', {
            essential: true,
            marketing: true,
            personalization: true,
            analytics: true,
        }, {
            expires: this.opciones.expiryDays
        })
        this.lanzarCallbacks()
        this.borrarCookiesNoSolicitadas()
        this.ocultarPopup()
    },

    ocultarPopup() {
        document.getElementById('galleton-popup').style.display = 'none'
        //this.contenedor.style.display = 'none'
    },

    log(mensaje) {
        if (this.opciones.log) {
            console.log(mensaje)
        }
    }
}

