class GalletonLoger {

    constructor(mostrar) {
        this.mostrar = mostrar
    }

    log(mensaje) {
        if (this.mostrar) {
            console.log(mensaje)
        }
    }
}

export default GalletonLoger
