class ValidadorHtml {

    constructor(opciones) {
        this.opciones = opciones
    }

    validar() {

    }

    esValido() {
        return true
    }
}

export default ValidadorHtml
